import $ from 'jquery'
import magnificPopup from 'magnific-popup'
const $doc = $(document)

function openVideoModal(target) {
    $("html").addClass('fixed');
    $.magnificPopup.open({
        items: {
            src: target,
            type: 'inline',
        },
        callbacks: {
            close: function () {
                const url = new URL(window.location)
                url.hash = ''
                history.pushState({}, '', url)
                $("html").removeClass('fixed');
            }
        },
        closeBtnInside: true
    });
}

$doc.ready(() => {
    $doc.on('click', '.js-play-video', e => { openVideoModal(e.target.hash) });
})

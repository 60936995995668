import $ from 'jquery'
// $(document).ready(initFeaturedPostsSlider)
const $sliderGrid = $('.js-pages-slider');

function initFeaturedPostsSlider() {
    if ($sliderGrid.length) {
        $sliderGrid.slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            dots: true,
            mobileFirst: true,
            fade: true,
        })
    }
}

if ($sliderGrid.length) {
    $(window).on('load resize orientationchange', function () {
        if ($(window).width() > 700) {
            if ($sliderGrid.hasClass('slick-initialized')) {
                $sliderGrid.slick('unslick');
            }
        }
        else {
            if (!$sliderGrid.hasClass('slick-initialized')) {
                initFeaturedPostsSlider()
            }
        }
    });
}

import $ from 'jquery'
import magnificPopup from 'magnific-popup'
$(document).ready( initLogoPopup )

function initLogoPopup() {
  $('.js-logo-modal').magnificPopup({
    type: 'inline',
    closeBtnInside: true,
    mainClass: 'logo-popup'
  });
}
